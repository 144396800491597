<template>
  <div class="box">
    <div class="search">
      <!-- <div class="search-top">
                <div v-for="(item,i) in toplist" :key="i">{{item.name}}（{{item.value}}）</div>
            </div> -->
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="优惠券状态：" prop="businessName">
              <el-select v-model="queryInfo.condition.effectiveStatus" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="优惠券名称：" prop="applicantTime">
              <el-input v-model="queryInfo.condition.couponsName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>


      </el-form>
    </div>
    <div class="tab">
      <div class="add">
        <el-button type="primary" @click="added">新增优惠券</el-button>
        <el-button type="primary" @click="send">发券</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 380px)" border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">



        <el-table-column prop="couponsName" label="优惠券名称" width="150" align="center">
        </el-table-column>
        <el-table-column prop="type" label="优惠券类型" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text">{{ scope.row.type == 1?'用户':scope.row.type==2?'商家':"平台" }}</el-button>

          </template>
        </el-table-column>
        <el-table-column prop="useIllustrate" label="优惠券内容" width="250" align="center">

        </el-table-column>
        <el-table-column prop="expirationNum" width="250" label="优惠券有效期" align="center">
          <template slot-scope="scope">
            <el-button type="text">{{ scope.row.startTime }}-{{ scope.row.endTime }}</el-button>
            

          </template>

        </el-table-column>
        <el-table-column prop="effectiveStatus" width="100" label="优惠券状态"   align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.effectiveStatus == 1" >下架</el-button>
            <el-button type="text" v-else style="color: red;" >上架</el-button>

          </template>

        </el-table-column>
        <el-table-column prop="totalcount" label="发放数量" width="150" align="center">

        </el-table-column>
        <el-table-column prop="oneCount" label="每人限领" align="center">

        </el-table-column>
        <el-table-column prop="totalcount" label="已领取数" align="center">

        </el-table-column>
        <el-table-column prop="creatTime" label="创建时间" width="150" align="center">

        </el-table-column>
        <el-table-column prop="openId" label="创建人" width="100" align="center">

        </el-table-column>

        <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <template>
              <el-popconfirm title="这是一段内容确定删除吗？" @onConfirm="handleDelte(scope.row.id)"
                @confirm="handleDelte(scope.row.id)">
                <el-button type="text" slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
            <el-button type="text" @click="deta(scope.row.id)">编辑</el-button>
            <el-button type="text" v-if="scope.row.effectiveStatus == 1" @click="upper(scope.row)">下架</el-button>
            <el-button type="text" v-else style="color: red;" @click="upper(scope.row)">上架</el-button>

          </template>


        </el-table-column>


      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="发放优惠券" :visible.sync="showDialog" width="500px" height="600px">
      <div class="dia">
        <el-form label-width="120px" label-height="60px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择用户：" prop="contactName">
                <el-input v-model="username" placeholder="请输入" @focus="userfocus" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="选择优惠券：" prop="contactName">
                <el-input v-model="couname" placeholder="请输入" @focus="couponfocus" clearable></el-input>
              </el-form-item>
            </el-col>

          </el-row>

        </el-form>

        <div class="diafour">
          <el-button type="danger" @click="showDialog = false">取消</el-button>
          <el-button type="primary" @click="save">发送 </el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog title="选择用户" :visible.sync="userDialog" width="600px" height="600px">
      <el-form label-width="100px" label-height="40px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="用户名称：" prop="contactName">
              <el-input v-model="name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户手机号：" prop="contactName">
              <el-input v-model="userphone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: right;">
           
            <el-button type="primary" @click="searchsj" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

      </el-form>
      <div class="dia">
        <el-table :data="tableUser" style="width: 100%" height="calc(100vh - 380px)"
          @selection-change="handleSelectionChange" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="memberNikeName" label="用户昵称" align="center">
          </el-table-column>
          <el-table-column prop="sex" label="性别" align="center">
          </el-table-column>
          <el-table-column width="150" prop="creatTime" label="创建时间" align="center">

          </el-table-column>


        </el-table>


        <div class="diafour">
          <el-button type="danger" @click="userDialog = false">取消</el-button>
          <el-button type="primary" @click="saveuser">确定 </el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog title="选择优惠券" :visible.sync="couponDialog" width="500px" height="600px">
      <div class="dia">
        <el-table :data="tablecoupon" style="width: 100%" height="calc(100vh - 380px)"
          @selection-change="handleSelectionChangecoupon" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="couponsName" label="优惠券名称" align="center">
          </el-table-column>
          <el-table-column prop="useIllustrate" label="优惠券内容" align="center">
          </el-table-column>



        </el-table>


        <div class="diafour">
          <el-button type="danger" @click="couponDialog = false">取消</el-button>
          <el-button type="primary" @click="savecoupon">确定 </el-button>
        </div>
      </div>

    </el-dialog>
  </div>

</template>

<script>
export default {
  data() {
    return {  
        options: [
        {
          value: '',
          label: '全部'
        },{
          value: '0',
          label: '下架'
        }, {
          value: '1',
          label: '上架'
        }],
      showDialogtwo: false,
      activeName: 'second',
      drawer: false,
      toplist: [{
        name: '全部',
        value: 33
      }, {
        name: '待审核',
        value: 33
      }, {
        name: '已通过',
        value: 33
      }, {
        name: '已拒绝',
        value: 33
      }],
      tableData: [],
      queryInfo: {
        condition: {
          type: 3
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10

      },
      total: 0,
      showDialog: false,
      id: '',
      value1: 0,
      showDialog: false,
      from: {},
      value: '',
      tableUser: [
        {
          areaImg: 'WDZ'
        }
      ],
      userDialog: false,
      userlist: [],
      tablecoupon: [],
      couponDialog: false,
      couponlist: [],
      couname:'',
      name:'',
      userphone:''

    };
  },
  mounted() {
    this.getList()
    
  },
  methods: {
    handleDelte(id) {
      var that = this;
      this.$http.post("/coupons/delete", {
        id: id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.$message.success("删除成功");

          that.getList()
        }
      });
    },
    searchsj(){
      var that = this;
      that.$http.post('/member/queryList',{
        memberNikeName:that.name,
        phone:that.userphone
      }).then(response => {
      console.log(response.data.data,'response.data.data');
      
        that.tableUser = response.data.data;
        console.log(that.restaurants,'that.restaurants');
        
        
        
      })
    },
    change(e) {
      console.log(e);
    },
    added() {
      this.$router.push({ path: '/coupondetail' })
    },
    deta(id){
      this.$router.push({ path: '/coupondetail' ,query:{
        id:id
      }})
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/coupons/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    //获取用户
    getuserList() {
      var that = this;
      that.$http.post("/member/queryList", {}).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.tableUser = response.data.data;
        }
      });
    },
    //获取优惠券
    getcouponList() {
      var that = this;
      that.$http.post("/coupons/queryList", {
        type: 3,
        effectiveStatus:1
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.tablecoupon = response.data.data;
        }
      });
    },
    search() {
      this.queryInfo.currPage = 1;
      this.getList()
    },
    reset() {
      this.queryInfo.condition = {
        id: '',
        businessName: '',
        applicantTime: '',
        linkPhone: '',
        scope: '',
        industryType: '',
        businessStatus: ''
      }
      this.queryInfo.currPage = 1;
      this.getList()
    },
    send() {
      this.showDialog = true
      this.getuserList()
      this.getcouponList()
    },
    //选择用户
    userfocus() {
     
      this.userDialog = true
    },
    handleSelectionChange(val) {
      console.log(val);
      
      this.userlist = val
    },
    saveuser() {
      this.username = this.userlist.map(item=>{
        return item.memberNikeName
      })
      this.userlist = this.userlist.map(item => {
        return item.openId
      })
      
      this.userDialog = false
      console.log(this.username,this.userlist);
      
    },
    savecoupon(){
      // const counamelist = this.couponlist.map(item=>{
      //   return item.couponsName
      // })
      this.couname = this.couponlist[0].couponsName
      this.couponDialog =false

    },
    couponfocus() {
      this.couponDialog = true
    },
    handleSelectionChangecoupon(val) {
      this.couponlist = val
    },
    save() {
      var that = this;
      that.$http.post("/coupons/sendCoupons", {
        couponsList: that.couponlist,
        openidList: that.userlist
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.$message({
            type: 'success',
            message: '发送成功'
          });
          that.showDialog = false;
          that.couname =''
          that.getList()
        }
      });
    },
    upper(row) {
      var that = this;
      this.$http.post("/coupons/updateStatus", {
        id: row.id,
        effectiveStatus: row.effectiveStatus
      }).then(function (response) {

        if (response.data.code == 200) {

          that.getList()
        }
      });



    },
          // 修改页数大小
          handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },


  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 20px 10px 20px;

    .search-top {
      height: 66px;
      padding: 2px 2px 2px 2px;
      background-color: rgba(30, 152, 215, 0.14);

      display: flex;
      align-items: center;
      margin-bottom: 50px;

      div {
        width: 150px;
        text-align: center;
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 130px;
    }
  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }

      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .diatwo {
    box-sizing: border-box;
    padding: 0 30px;

    .dialist {
      display: flex;
      flex-wrap: wrap;

      .dia-li {
        width: 300px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .name {
          font-weight: 700;
          margin-right: 20px;

        }

        img {
          width: 48px;
          height: 47px;
        }
      }
    }

    .diaimg {
      display: flex;
      flex-wrap: wrap;

      .imgbox {
        width: 300px;
        margin-bottom: 15px;

        .name {
          font-weight: 700;

        }

        .imgli {
          display: flex;
          margin-top: 15px;

          img {
            width: 92px;
            height: 65px;
            margin-right: 10px;
          }
        }
      }
    }

    .sh {
      display: flex;
      margin-top: 40px;

      textarea {
        width: 679px;
        height: 60px;
        margin-left: 10px;
      }

    }

    .but {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      padding: 0 300px;
    }
  }
}
</style>