import { render, staticRenderFns } from "./coupon.vue?vue&type=template&id=81a8f1fa&scoped=true"
import script from "./coupon.vue?vue&type=script&lang=js"
export * from "./coupon.vue?vue&type=script&lang=js"
import style0 from "./coupon.vue?vue&type=style&index=0&id=81a8f1fa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a8f1fa",
  null
  
)

component.options.__file = "coupon.vue"
export default component.exports